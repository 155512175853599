// @import '../../modules/amwc/theme/';

/**
 * Top navigation header. Takes up a fixed height of 106px as per design.
 */
.nav {
  flex: 0 0 106px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  background-color: #333f48;

  // Right side of the header for displaying links and buttons (e.g. "Sign-out" button).
  .navRight {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
  }

  .logos {
    display: flex;
    align-items: stretch;
    padding: 25px 0;
    box-sizing: border-box;

    .logo {
      margin: 0 15px;
      box-sizing: border-box;
    }

    .textLogo {
      margin: 0;
      box-sizing: border-box;
    }
  }
}
.body {
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
}

// See Body component in ./Body.tsx. Takes up the remaining vertical space on the page
// after the header. A scrollbar is added to this element when any content
// is overflowing vertically.
.contentBody {
  flex: 1 1 auto;

  &[data-padded="true"] {
    padding: 0 16px;
  }

  &[data-size="1"] {
    margin: 0 auto;
    max-width: 453px;

    @media screen and (min-width: 453px) {
      > div {
        min-width: 453px;
      }
    }
  }

  &[data-size="2"] {
    margin: 0 auto;
    max-width: 906px;

    @media screen and (min-width: 906px) {
      > div {
        min-width: 906px;
      }
    }
  }

  &[data-grid="true"] {
    > div {
      display: flex;
      flex-wrap: wrap;
      @media screen and (min-width: 906px) {
        flex-wrap: nowrap;
      }

      > * {
        flex: 0 0 50%;

        &[data-centered] {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media all and (-ms-high-contrast: none) {
    //  .foo { color: green } /* IE10 */
    *::-ms-backdrop,
    .center {
      display: block;
    } /* IE11 */
  }
}

:not(.contentBody) + .bottom {
  flex: 1 1 auto;

  &[data-small-screen="true"] {
    @media screen and (max-width: 906 / 2 * 1px) {
      flex: initial;
    }
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > * {
    flex: 0 0 auto;
  }

  button {
    width: 100%;
  }

  &[data-padded="true"] {
    padding: 0 16px;
  }
}

.footer {
  box-sizing: border-box;
  flex: 0 0 85px;
  background: #333f48;
  color: #fff;
  padding: 0 24px;
  display: flex;
  align-items: center;

  &[data-hide="true"] {
    display: none;
  }

  > div:first-child {
    flex: 1 1 auto;
  }

  > div:nth-child(2) {
    a {
      color: #fff;
    }

    a:first-child {
      margin-right: 15px;
    }
  }

  justify-content: center;
  flex-wrap: wrap;
  white-space: nowrap;
  text-align: center;

  > * {
    flex: 0 0 auto;

    &:first-child {
      order: 1;
    }
  }

  @media screen and (min-width: 600px) {
    justify-content: center;
    flex-wrap: nowrap;

    text-align: left;

    > * {
      flex: initial;

      &:first-child {
        order: 0;
      }
    }
  }
}
